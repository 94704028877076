<script setup>
import { defineProps } from 'vue';
import useRotas from '../../Composables/useRotas';

const props = defineProps({
    contagens: Object,
    categorias: Array,
});

const route = useRotas();

const getCount = (type, category, subCategory) => {
    const correctCount = props.contagens?.categorias?.filter((contagem) => (
        contagem?.tipo === type
        && contagem?.categoria === category
        && contagem?.subCategoria === subCategory
    ))[0];
    return correctCount?.total || 0;
};
</script>

<template>
    <div :class="$q.screen.lt.md ? '' : 'q-pa-md'">
        <div
            v-if="$q.screen.gt.sm"
            class="flex bg-primary justify-center items-center text-white"
            style="height: 3em; border-top-right-radius: 5px;
      border-top-left-radius: 5px; margin-bottom: -2px"
        >
            Categorias
        </div>
        <q-list bordered class="rounded-borders">
            <q-item clickable @click="$inertia.visit(route('home'))">
                <q-item-section avatar>
                    <q-avatar
                        color="primary"
                        icon="mdi-format-list-bulleted-square"
                        text-color="white"
                    />
                </q-item-section>

                <q-item-section>
                    Página Inicial
                </q-item-section>
                <q-item-section
                    side
                    style="display: flex; flex-direction: row; align-content: center; justify-content: center"
                >
                    <div
                        class="row items-center" style="line-height: 24px">
                        {{ contagens.total }}
                    </div>
                    <q-icon
                        color="grey" name="mdi-chevron-right"
                    />
                </q-item-section>
            </q-item>
            <q-expansion-item
                v-for="categoria of categorias"
                :key="categoria.tipo + '-' + categoria.slug"
                v-show="getCount(categoria.tipo, categoria.slug, null) > 0"
            >
                <template v-slot:header>
                    <q-item-section avatar>
                        <q-avatar
                            :icon="categoria.icone_site"
                            color="primary"
                            text-color="white"
                        />
                    </q-item-section>

                    <q-item-section>
                        {{ categoria.nome }}
                    </q-item-section>

<!--                    <q-item-section side>-->
<!--                        <div class="row items-center">-->
<!--                            {{ getCount(categoria.tipo, categoria.slug, null) }}-->
<!--                        </div>-->
<!--                    </q-item-section>-->
                </template>
                <q-card class="q-pa-none q-ma-none">
                    <q-card-section class="q-pa-none q-ma-none">
                        <q-list class="q-pa-none q-ma-none">
                            <q-item
                                @click="$inertia.visit(route('tipos.categorias.categoria', {tipo: categoria.tipo, categoria: categoria.slug}))"
                                clickable>
                                <q-item-section>
                                    <q-item-label
                                        class="q-ml-md"
                                    >
                                        Ver Tudo
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side style="display: flex;
                                  flex-direction: row;
                                  align-content: center; justify-content: center">
<!--                                    <div-->
<!--                                        class="row items-center" style="line-height: 24px">-->
<!--                                        {{ getCount(categoria.tipo, categoria.slug, null) }}-->
<!--                                    </div>-->
                                    <q-icon
                                        color="grey" name="mdi-chevron-right"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-for="subCategoria in categoria.subcategorias"
                                :key="subCategoria.nome"
                                @click="$inertia.visit(route('tipos.categorias.subcategorias.subcategoria', {tipo: categoria.tipo, categoria: categoria.slug, subcategoria: subCategoria.slug}))"
                                clickable
                            >
                                <q-item-section>
                                    <q-item-label
                                        class="q-ml-md"
                                    >
                                        {{ subCategoria.nome }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side style="display: flex;
                                  flex-direction: row;
                                  align-content: center; justify-content: center">
<!--                                    <div-->
<!--                                        class="row items-center" style="line-height: 24px">-->
<!--                                        {{ getCount(categoria.tipo, categoria.slug, subCategoria.slug) }}-->
<!--                                    </div>-->
                                    <q-icon
                                        color="grey" name="mdi-chevron-right"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-card-section>
                    <q-separator/>
                </q-card>
            </q-expansion-item>
            <q-separator/>
        </q-list>
    </div>
</template>
